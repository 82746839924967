import { Module } from 'vuex';

// Define the SoundbyteData type if it's a specific structure. Otherwise, use `object`.
type SoundbyteData = {
  id: number;
  title: string;
};

type ModalState = {
  openFileUploadSuccessModal: boolean;
  uploadedSoundbyte: object | null;
  openSoundbyteDeleteConfirmationModal: boolean;
  soundbyteDeleteConfirmationId: number | null;
  deleteConfirmationId: number | null;
  openDeleteConfirmationModal: boolean;
  openShareSoundbyteModal: boolean;
};

const modal: Module<ModalState, any> = {
  namespaced: true,
  state: {
    openFileUploadSuccessModal: false,
    uploadedSoundbyte: null,
    openSoundbyteDeleteConfirmationModal: false,
    soundbyteDeleteConfirmationId: null,
    openDeleteConfirmationModal: false,
    deleteConfirmationId: null,
    openShareSoundbyteModal: false,
  },
  getters: {
    openFileUploadSuccessModal: (state) => state.openFileUploadSuccessModal,
    uploadedSoundbyte: (state) => state.uploadedSoundbyte,
    openShareSoundbyteModal: (state) => state.openShareSoundbyteModal,
    openSoundbyteDeleteConfirmationModal: (state) => state.openSoundbyteDeleteConfirmationModal,
    soundbyteDeleteConfirmationId: (state) => state.soundbyteDeleteConfirmationId,
    deleteConfirmationId: (state) => state.deleteConfirmationId,
    openDeleteConfirmationModal: (state) => state.openDeleteConfirmationModal,
  },
  mutations: {
    toggleOpenFileUploadSuccessModal(state, payload: boolean) {
      state.openFileUploadSuccessModal = payload;
    },
    setUploadedSoundbyte(state, soundbyte: SoundbyteData | null) {
      state.uploadedSoundbyte = soundbyte;
    },
    toggleOpenShareSoundbyteModal(state, payload: boolean) {
      state.openShareSoundbyteModal = payload;
    },
    toggleOpenSoundbyteDeleteConfirmationModal(state, payload: boolean) {
      state.openSoundbyteDeleteConfirmationModal = payload;
    },
    toggleOpenDeleteConfirmationModal(state, payload: boolean) {
      state.openDeleteConfirmationModal = payload;
    },
    updateSoundbyteDeleteConfirmationId(state, payload: number | null) {
      state.soundbyteDeleteConfirmationId = payload;
    },
    updateDeleteConfirmationId(state, payload: number | null) {
      state.deleteConfirmationId = payload;
    },
  },
};

export default modal;
